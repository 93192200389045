@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Epilogue", sans-serif;
}
/* <---------- var start ----------> */
:root{
    --Ocean_Blue: #007BFF; 
    --Digital_Gold : #D9B701; 
    --Forest_Green: #4CAF50; 
    --Unity_Grey: #808080; 
    --Fern_Frond : #4F7942; 
    --Harmony_Teal: #008080;
    --black: #000000;
    --white: #ffffff;
    --header_height: 100px;
    --section_space: 80px;
    /* scroll-behavior: inherit; */
}
/* <---------- var end ----------> */
a{
    text-decoration: none;
}
p{
    margin-bottom: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}
h1,h2,h3,h4,h5,h6{
    margin-bottom: 0;
}
h2{
    color: var(--black);
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    text-transform: capitalize;
}
h3{
    color: var(--black);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
h5{
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}
h6{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--white);
}
.two-space{
    padding: var(--section_space) 0;
}
.top-space{
    padding-top: var(--section_space);
}
.bot-space{
    padding-bottom: var(--section_space);
}
.m-w{
    font-weight: 500;
}
.s-w{
    font-weight: 600;
}
.Ocean_Blue_bg{
    background-color: #007BFF !important;
}
.Digital_Gold_bg{
    background-color: #D9B701 !important;
}
.Forest_Green_bg{
    background-color: #4CAF50 !important;
}
.Unity_Grey_bg{
    background-color: #808080 !important;
}
.Fern_Frond_bg{
    background-color: #4F7942 !important;
}
.Harmony_Teal_bg{
    background-color: #008080 !important;
}

.Ocean_Blue_text{
    color: #007BFF !important;
}
.Digital_Gold_text{
    color: #D9B701 !important;
}
.Forest_Green_text{
    color: #4CAF50 !important;
}
.Unity_Grey_text{
    color: #808080 !important;
}
.Fern_Frond_text{
    color: #4F7942 !important;
}
.Harmony_Teal_text{
    color: #008080 !important;
}
svg path{
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
}
.fill-btn{
    height: 50px;
    background-color: var(--black);
    color: var(--white);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    border: 1px solid transparent;
    padding: 15px 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fill-btn:hover{
    background-color: var(--Forest_Green) !important;
    color: var(--white) !important;
    border-color: transparent !important;
}
.fill-btn:hover svg path{
    fill: var(--white);
}
.border-btn{
    background-color: transparent;
    color: var(--black);
    border-color: var(--black);
}
/* .border-btn:hover{
    background-color: transparent;
    color: var(--black);
    border-color: var(--black);
} */

/* <------------- number arrow start ------------> */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
/* <------------- number arrow end ------------> */

.number-text{
    font-family: "Poppins", sans-serif !important;
}
.main-wapper{
    width: 100%;
    position: relative;
    overflow-x: hidden;
}


/* <---------- header start ----------> */
#header{
    height: var(--header_height);
}
.header-block{
    background-color: var(--Ocean_Blue);
}
.logo-bar img{
    max-width: 300px;
    object-fit: contain;
}
.header-caption .fill-btn{
    font-weight: 600;
}
/* <---------- header end ----------> */

/* <---------- footer start ----------> */
.footer-block .section-bg{
    padding: 40px 30px;
}
/* .listing-link li .nav-link{
    color: rgba(255, 255, 255, 0.55);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.listing-link li .nav-link:hover{
    color: #fff;
}
.footer-caption .link-block{
    column-gap: 70px;
} */

.footer-bot-caption p, .footer-bot-caption .link-text{
    color: rgba(255, 255, 255, 0.55);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
}
.social-media-bar .media-item{
    width: 32px;
    height: 32px;
}
.social-media-bar .media-item svg{
    width: 16px;
}
/* <---------- footer end ----------> */

.about-leos-section h2{
    color: var(--Forest_Green);
}
.text-caption span{
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--Ocean_Blue);
}

.buy-leos-form-block .input-group{
    height: 48px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.29);
}
.buy-leos-form-block .input-group .input-group-text{
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}
.buy-leos-form-block .form-control{
    font-family: "Poppins", sans-serif !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: var(--white);
}
.form-control:focus{
    box-shadow: none;
}
.convert-leos-capton .text-caption .convert-leos-text, .convert-leos-capton .text-caption .convert-leos-text span{
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}
.features-section .section-bg{
    background-color: #F5FAF5;
}
.features-listing-box .item-box{
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 25px 30px;
}
.link-arrow-text p{
    font-size: 14px;
}
.chatgpt-box{
    width: 100%;
    background-image: url(../img/gpt-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 30px 0;
}
.chatgpt-box p{
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}
.heading-box span{
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

/* <---------- token sale start ----------> */
.token-sale-box{
    padding: 50px;
    border-radius: 15px;
    border: 2px solid var(--Forest_Green);
}
.token-sale-box .token-unlock-caption{
    border-radius: 10px;
    padding: 25px;
}
.token-sale-box .token-unlock-caption .text-caption span{
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
}
.token-sale-box .token-unlock-caption .text-caption span p{
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.tokensale-info-box{
    border-radius: 10px;
    background: #F9F9F9;
    padding: 20px;
    height: 100%;
}
.tokensale-info-box .title{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.tokensale-info-box span{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
/* <---------- token sale end ----------> */

/* <---------- token-information-box start ----------> */
.token-information-box{
    padding: 70px 50px 0px 50px;
    background: linear-gradient(180deg, #E6F9FF -1.67%, rgba(247, 247, 247, 0.00) 100%);
    border-radius: 40px 40px 0px 0px;
}
.token-information-box .information-listing li p{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: #5C5C5C;
}
.token-information-box .information-listing li span{
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: var(--black);
}
.shadow-box-wapper{
    max-width: 830px;
}
.shadow-box{
    padding: 25px 30px;
    border-radius: 15px;
    background-color: var(--white);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);
}
.shadow-box .title{
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--black);
}
/* <---------- token-information-box end ----------> */

/* <---------- table start ----------> */
.table{
    table-layout: fixed;
}
.table-wapper{
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.table thead tr th{
    background-color: var(--Harmony_Teal);
    color: var(--white);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border: 1px solid #EDF1FD;
    padding: 20px 15px;
    vertical-align: middle;
}
.table thead tr th:first-child{
    border-radius: 10px 0 0 0;
    -webkit-border-radius: 10px 0 0 0;
    -moz-border-radius: 10px 0 0 0;
    -ms-border-radius: 10px 0 0 0;
    -o-border-radius: 10px 0 0 0;
}
.table thead tr th:last-child{
    border-radius: 0 10px 0 0;
    -webkit-border-radius: 0 10px 0 0;
    -moz-border-radius: 0 10px 0 0;
    -ms-border-radius: 0 10px 0 0;
    -o-border-radius: 0 10px 0 0;
}
.table tbody tr td{
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #EDF1FD;
    padding: 20px 15px;
    vertical-align: middle;
}
.table tbody tr td:first-child{
    text-align: start;
    font-size: 15px;
    font-weight: 600;
}
/* <---------- table end ----------> */

/* <---------- select flag box start ----------> */
.flag-dropdown{
    padding: 0 !important;
}
.selected-flag-btn{
    border: none !important;
    /*height: 24px;
    */background-color: #00264F !important;
    border-radius: 100px !important;
    -webkit-border-radius: 100px !important;
    -moz-border-radius: 100px !important;
    -ms-border-radius: 100px !important;
    -o-border-radius: 100px !important;
    color: #fff !important;
    font-size: 12px !important;
    height: 32px !important;
    padding: 5px 5px !important;
}
.selected-flag-btn svg path{
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
}
.selected-flag-btn .ReactFlagsSelect-module_selectValue__152eS{
    margin: 0px !important;
    padding-right: 0px !important;
    font-family: "Poppins", sans-serif !important;
}
.selected-flag-btn .ReactFlagsSelect-module_label__27pw9{
    padding-left: 5px !important;
    font-family: "Poppins", sans-serif !important;
}
.selected-flag-btn:after{
    border-top-color: var(--white) !important;
}
.selected-flag-btn[aria-expanded="true"]:after{
    border-bottom-color: var(--white) !important;
}
.flag-dropdown .ReactFlagsSelect-module_fullWidthOptions__1XeR6{
    min-width: 120px;
    left: inherit;
}
.flag-dropdown .ReactFlagsSelect-module_label__27pw9{
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    padding-left: 5px;
}
/* <---------- select flag box end ----------> */

/* <---------- stepform wapper starts ----------> */
.stepform-wapper .stepform-wapper .container, .stepform-wapper .stepform-block, .content-wapper{
    width: 100%;
    min-height: calc(100vh - var(--header_height));
}
.stepform-progress .StepperContainer-0-2-1{
    background-color: #F9F9F9;
    padding: 25px 40px;
    border-radius: 0px 0px 20px 20px;
}
.stepform-progress #RFS-Connector{
    border-style: dashed;
    border-width: 1px;
}
.stepform-progress #RFS-StepButton span{
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    font-family: "Poppins", sans-serif !important;
}
.stepform-progress #RFS-Label{
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}
.step-banner-imgbar img{
    width: 100%;
    object-fit: contain;
}
.step-banner-block .caption-box{
    gap: 33px;
}
.step-banner-block .caption-box h3{
    font-weight: 300;
    text-transform: inherit;
}
.step-banner-block .caption-box p{
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #363636;
}
.nationality-step-block p{
    display: none;
}
.listing-item-box{
    list-style-position: inside;
}
.step-banner-block .listing-item-box li{
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #363636;
}
.nationality-step-block .listing-item-box, .nationality-step-block .step-radio-btn-bar, .login-step-block .subscribe-marketing-checkbox{
    display: block !important;
}
.nationality-step-block .step-link-btn-bar{
    display: none !important;
}
.label-radio-btn .form-check-input{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
}
.label-radio-btn .form-check-label{
    border-radius: 5px;
    background-color: transparent;
    padding: 12px 20px;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.label-radio-btn .form-check-label span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.label-radio-btn .form-check-label.grey-radio-btn{
    border-color: var(--Unity_Grey);
}
.label-radio-btn .form-check-label.blue-radio-btn{
    border-color: var(--Ocean_Blue);
}
.label-radio-btn .form-check .form-check-input:checked + .form-check-label{
    border-color: transparent;
    color: var(--white);
}
.label-radio-btn .form-check .form-check-input:checked + .form-check-label.grey-radio-btn{
    background-color: var(--Unity_Grey);
}
.label-radio-btn .form-check .form-check-input:checked + .form-check-label.grey-radio-btn svg path{
    fill: var(--white) !important;
}
.label-radio-btn .form-check .form-check-input:checked + .form-check-label.blue-radio-btn{
    background-color: var(--Ocean_Blue)
}
.label-radio-btn .form-check .form-check-input:checked + .form-check-label.blue-radio-btn svg path{
    fill: var(--white) !important;
}
.payment-step-box{
    width: 100%;
    max-width: 810px;
    margin: 0 auto;
    padding: 34px 24px;
    gap: 40px;
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    background-color: var(--white);
}
.amount-listing li{
    padding: 10px 0;
    border-bottom: 1px dashed #E0E0E0;
}
.amount-listing li .label{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.amount-listing li .price{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.payment-option-bar .heading{
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--black);
}
.payment-option-btn .form-check-label{
    border-color: transparent !important;
    border-width: 2px !important;
    background: #F8F8F8;
    padding: 15px 10px;
}
.payment-option-btn .form-check .form-check-input:checked + .form-check-label{
    border-color: var(--Ocean_Blue) !important;
    background-color: rgba(0, 123, 255, 0.04) !important;
    color: var(--Ocean_Blue) !important;
}
.payment-option-btn .form-check .form-check-input:checked + .form-check-label svg path{
    fill: var(--Ocean_Blue) !important;
}
.payment-option-btn .form-check .form-check-input:checked + .form-check-label  .checked-icon{
    opacity: 1;
    visibility: visible;
}
.payment-option-btn .form-check .form-check-input:checked + .form-check-label  .checked-icon svg path{
    fill: var(--white) !important;
}
.payment-option-btn .form-check-label span{
    font-size: 18px;
}
.payment-option-info{
    border-radius: 10px;
    border: 1px solid #FFED8F;
    padding: 20px;
    background: rgba(255, 248, 221, 0.22);
}
.payment-option-info .info-payemnt-listing {
    list-style-position: inside;
}
.payment-option-info .info-payemnt-listing li{
    font-size: 14px;
    color: var(--black);
}
.form-check-label .checked-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
.checked-icon{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: var(--Ocean_Blue);
    opacity: 0;
    visibility: hidden;
}
/* <--------- new start ------> */
.payment-option-btn .form-check-label .payment-method-box .img-bar img{
    max-width: 30px;
    max-height: 20px;
}
.payment-option-btn .form-check-label .payment-method-box .name{
    font-size: 13px;
    font-weight: 400;
    color: var(--black);
}
.payment-option-btn .form-check-label .payment-method-box .fee-text{
    font-size: 11px;
    font-weight: 500;
    color: var(--Unity_Grey)
}
/* <--------- new end ------> */

.square-tick-check .form-check-input{
    width: 20px;
    height: 20px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    cursor: pointer;
    background-color: #F9F9FB;
}
.subscribe-marketing-checkbox .form-check{
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    padding: 15px !important;
}
.square-tick-check .form-check-input:checked[type=checkbox]{
    background-color: var(--Ocean_Blue);
    border-color: transparent;
}
.square-tick-check .form-check-label{
    cursor: pointer;
    font-size: 14px;
}
.square-tick-check .form-check-input:focus{
    box-shadow: none;
    outline: none;
}
/* <---------- stepform wapper end ----------> */

/* <---------- paymentsuccessfull wapper start ----------> */
.paymentsuccessfull-wapper .caption-box {
    border-radius: 20px;
    border: 1px solid rgba(76, 175, 80, 0.20);
    background: var(--white);
}
.paymentsuccessfull-wapper .caption-box .label-box{
    background: rgba(76, 175, 80, 0.09);
    padding: 30px 15px;
}
.paymentsuccessfull-wapper .caption-box .body-box{
    padding: 30px 50px;
}
.paymentsuccessfull-wapper .caption-box .body-box .amount-listing li .price{
    font-size: 16px;
}
.paymentsuccessfull-wapper .caption-box .body-box .text-caption p{
    font-size: 18px;
}
.paymentsuccessfull-wapper .caption-box .body-box .text-caption span{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    color: var(--black);
}
.modal .modal-body{
    padding: 40px 50px;
}
/* <---------- paymentsuccessfull wapper end ----------> */




